<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ item.sku }} - {{ item.customer_goods_barcode }}
    </v-card-title>
    <v-card-text>{{ $t("labels.update_image_description") }}</v-card-text>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <img
            v-if="url_images"
            :src="url_images"
            alt=""
            style="width: 100%; height: auto"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="url_images"
            class="c-input-xs mb-3"
            type="text"
            :label="$t('labels.enter_image_link')"
            dense
            outlined
            clearable
            hide-details
          ></v-text-field>
          <div class="mt-2">
            <label>{{ $t("labels.or_upload_image") }}</label>
            <input
              type="file"
              ref="inputUploadFile"
              accept="image/*"
              @change="onInputFileChange"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn color="green darken-1" text @click="confirm">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { /*compress,*/ compressAccurately } from "image-conversion";

export default {
  name: "UpdateImage",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    url_images: null,
  }),
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async confirm() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/goods-update-image", {
          id: this.item.id,
          url_images: this.url_images,
        });
        this.isLoading = false;
        this.cancel();
        this.$emit("refreshData");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async onInputFileChange(e) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      let files = e.target.files || e.dataTransfer.files;
      const file = files[0];
      const resBlob = await compressAccurately(file, {
        size: 250,
        type: "image/jpeg",
      });
      const newFile = new File(
        [resBlob],
        `${this.item.customer_goods_barcode}.jpg`,
        { type: "image/jpeg" }
      );

      let fd = new FormData();
      fd.append("file", newFile);

      try {
        const { data } = await httpClient.post(`/upload-image`, fd);
        this.url_images = `${process.env.VUE_APP_FILE_CDN_URL}/${data}`;
        this.isLoading = false;
      } catch (err) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.$refs.inputUploadFile.value = null;
      }
    },
  },
};
</script>

<style scoped></style>
